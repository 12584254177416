$themeGold: #caa352;

.wrapper {
  display: flex;
  flex-direction: column;

  .title {
    margin-bottom: 24px;
    font-size: 32px;
  }

  .sectionTitle {
    margin-bottom: 12px;
    font-size: 24px;
  }

  .imagesSection {
    display: flex;
    justify-content: space-between;
    margin-bottom: 64px;
    .imageWrapper {
      text-align: center;
      width: 30%;
      height: 10vw;
      flex: 1;
    }
    .image {
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
    .selected {
      box-shadow: 0px 0px 0px 3px $themeGold;
      border-radius: 4px;
    }
    .unselected {
      border: 1px solid #696969;
      border-radius: 4px;
    }
    .name {
      margin-top: 6px;
    }
  }
}
