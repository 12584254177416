$themeGold: #caa352;

.stageToggler {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;

  .prev {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 45%;
    cursor: pointer;
    text-align: center;
    border: 1px solid $themeGold;
    color: $themeGold;
  }

  .next {
    padding-top: 16px;
    padding-bottom: 16px;
    width: 45%;
    cursor: pointer;
    text-align: center;
    background-color: $themeGold;
    color: #fff;
  }
}
