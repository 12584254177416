.dot {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
}

.step {
  cursor: pointer;
}

.step:hover {
  color: #daa520 !important;
}
