$themeGold: #caa352;

.skeleton {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 100px;

  .row {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .topItem {
      margin-left: -7vw;
      margin-bottom: -1.5vw;
      height: 100%;
      width: 33.333%;
    }
    .item {
      margin-left: -7vw;
      margin-bottom: -3vw;
      height: 100%;
      width: 33.333%;
      cursor: pointer;
    }
    .item:hover {
      border: 1px solid $themeGold;
    }
  }
}

.deleteToolTip {
  cursor: pointer;
}

.deleteBtn {
  width: 100px;
  margin-left: 12px;
  margin-top: 12px;
  margin-bottom: 100px;
  background-color: $themeGold;
  text-align: center;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}
