$themeGold: #caa352;

.skeleton {
  width: 30%;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 100px;

  .row {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .topItem {
      margin-left: -7vw;
      margin-bottom: -3vw;
      height: 100%;
      width: 33.333%;
    }
    .item {
      margin-left: -7vw;
      margin-bottom: -3vw;
      height: 100%;
      width: 33.333%;
    }
  }
}
