.header {
  display: flex;
  height: 81px;
  line-height: 81px;
  padding-left: 55px;
  padding-right: 55px;
  font-family: Amiri, serif;

  .left {
    flex: 1;
  }

  .middle {
    display: flex;
    justify-content: center;
    list-style-type: none;
    text-align: center;
    flex: 5;
  }

  .right {
    flex: 1;
  }

  img {
    height: 50px;
    width: 50px;
  }

  .icon {
    font-size: 20px;
    font-weight: 700;
    line-height: 81px;
    float: right;
    cursor: pointer;
  }

  .item {
    color: rgb(58, 58, 58);
    font-family: Amiri, serif;
    font-size: 15px;
    font-weight: 400;
    margin-left: 10px;
    margin-right: 10px;
    padding: 3px;

    .text:hover {
      cursor: pointer;
      border-bottom: 1px solid black;
      color: grey;
    }
    .icon {
      margin-left: 6px;
    }
  }
}
