.balloonTypeSection {
  font-size: large;
  width: 100%;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  .arrow {
    margin-top: 5px;
  }
}

.imagesWrapper {
  border: 1px solid #eee;
  padding: 6px;
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-height: 500px;
  overflow: scroll;
  align-items: center;
  grid-row-gap: 12px;
  column-gap: 16px;
  img {
    cursor: pointer;
    width: 100%;
  }
}
