.wrapper {
  display: flex;
  width: 95%;
  min-height: 70vh;
  margin-left: auto;
  margin-right: auto;
  font-family: Amiri, serif !important;

  .left {
    flex: 2;
  }
  .middle {
    flex: 3;
    margin-left: 20px;
    margin-right: 20px;
  }
  .right {
    flex: 1;
  }

  .hidden {
    display: none;
  }
}
