.subTitle {
  margin-bottom: 12px;
  font-size: 24px;
}

.balloonNumberSlider {
  display: flex;
  flex-direction: row;
  .slider {
    flex: 9;
  }

  span {
    flex: 1;
    text-align: right;
    line-height: 32px;
  }
}
