.bouquetCard {
  flex: 1;
  display: flex;
  background-color: #fbf6f6;
  height: 20vw;
  border-radius: 15px;

  .left {
    flex: 1;
    text-align: left;
    padding-top: 3vw;
    padding-left: 24px;
    position: relative;
    .top {
      font-weight: 600;
      font-size: x-large;

      .subTitle {
        margin-top: 2px;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .bottom {
      cursor: pointer;
      font-weight: 600;
      color: #fff;
      background-color: rgb(202, 163, 82);
      position: absolute;
      bottom: 3vw;
      padding: 12px;
      border-radius: 5px;
      color: #000;
    }
  }

  .right {
    flex: 1;
    text-align: right;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}
