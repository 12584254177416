$dark: rgb(51, 50, 50);

.footer {
  background-color: #f6f6f6;
  .footerWrapper {
    width: 66%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1090px;
    display: flex;
    justify-content: space-between;
    margin-top: 55px;
    padding-top: 45px;
    padding-bottom: 35px;
    font-family: Amiri, serif;
    font-size: 17px;

    div {
      a {
        color: #333232;
      }
      a:hover {
        opacity: 0.7;
        border-bottom: 1px solid #333232;
      }
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      a {
        color: #333232;
      }
      a:hover {
        opacity: 0.7;
        border-bottom: 1px solid #333232;
      }
    }
  }
}
