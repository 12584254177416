.wrapper {
  font-size: 16px !important;
  display: block;
  width: 47vw;
  position: absolute;
  left: 0;
  right: 0;
  border: 1px solid rgb(235, 235, 235);
  z-index: 9px;
  background-color: rgb(255, 255, 255);
  padding-left: 24px;
  padding-top: 32px;
  padding-bottom: 32px;
  text-align: left;

  .topList {
    list-style-type: none !important;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    li {
      margin-right: 24px;
      margin-bottom: 24px;
      a {
        span:hover {
          color: grey;
          border-bottom: 1px solid #3a3a3a;
        }
      }
    }
    span {
      color: #3a3a3a;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }

  .unorderedList {
    list-style-type: none !important;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 8px;
      a {
        span {
          margin-left: 0;
          margin-right: 0;
        }
        span:hover {
          color: grey;
          border-bottom: 1px solid #3a3a3a;
        }
      }
    }

    li {
      line-height: 24px;
      border-bottom: 1px solid #fff;
    }
    span {
      color: #3a3a3a;
    }
  }
}
