.wrapper {
  margin-bottom: 60px;
  .subTotal {
    margin-top: 30px;
    border-top: 1px solid #eee;
    padding-top: 24px;
    display: flex;
    justify-content: flex-end;
  }
}
