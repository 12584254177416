.wrapper {
  list-style-type: none !important;
  color: black;
  border: 1px solid rgb(235, 235, 235);
  background-color: rgb(255, 255, 255);
  padding-left: 12px;
  padding-right: 48px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: left;

  li:not(:last-child) {
    margin-bottom: 12px;
  }

  li {
    a {
      span {
        color: black;
      }
      span:hover {
        color: grey;
        border-bottom: 1px solid #3a3a3a;
      }
    }
  }
}
