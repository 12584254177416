.imagesWrapper {
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-height: 500px;
  overflow: scroll;
  align-items: center;
  grid-row-gap: 12px;
  column-gap: 16px;
  img {
    cursor: pointer;
    width: 100%;
  }
}

.modalTitle {
  margin-bottom: 6px;
}

.sizeBtn {
  margin-right: 6px;
}
