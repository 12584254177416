.landingImageConatiner {
  position: relative;
  font-family: Amiri, serif;

  .landingImage {
    width: 100%;
    height: 33vw;
    object-fit: cover;
  }

  .imgText {
    position: absolute;
    bottom: 32px;
    left: 32px;
    font-size: 1.5vw;
    color: #fff;
    text-shadow: 3px 3px 8px #caa352;
  }

  .title {
    font-size: 3vw;
    font-weight: 600;
  }
}
