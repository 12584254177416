$themeGold: #caa352;

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #fff;
  border-color: $themeGold !important;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
  background-color: $themeGold !important;
}

.ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: $themeGold !important;
}
