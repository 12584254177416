.wrapper {
  margin-bottom: 64px;

  .title {
    margin-bottom: 24px;
    font-size: 32px;
  }

  .sectionTitle {
    margin-bottom: 12px;
    font-size: 24px;
  }
}
