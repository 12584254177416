.itemWrapper {
  height: 100px;
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  border-radius: 4px;
  .itemImage {
    height: 100px;
  }
}
